<template>
  <div v-if="checkPermission('list', 'action', 'credit-topup-reports')">
    <topic-component
        :topic="$t('SideBarItems.reports.list.reportCreditTopUp')"
    />
    <b-row class="mb-1">
      <b-col class="box-topic-credit">
        <b-card class="h-100">
          <div align="center">
            <h1>
              {{ changeNumberFormat(summary.total_amount) }}
            </h1>
            <h5>{{ $t('report.creditTopUp.totalTopUpAmount') }}</h5>
          </div>
        </b-card>
      </b-col>
      <b-col class="box-topic-credit">
        <b-card class="h-100">
          <div align="center">
            <h1>{{ changeNumberFormat(summary?.total_deduct_amount) }}</h1>
            <h5>{{ $t('report.creditTopUp.totalReduceCradit') }}</h5>
          </div>
        </b-card>
      </b-col>
      <b-col class="box-topic-credit">
        <b-card class="h-100">
          <div align="center">
            <h1>{{ changeNumberFormat(summary?.total_employee_amount) }}</h1>
            <h5>{{ $t('report.creditTopUp.amountOfCreditByEmployee') }}</h5>
          </div>
        </b-card>
      </b-col>
      <b-col class="box-topic-credit">
        <b-card class="h-100">
          <div align="center">
            <h1>{{ changeNumberFormat(summary?.total_package_amount) }}</h1>
            <h5>{{ $t('report.creditTopUp.amountOfCreditByPackage') }}</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label-cols="12"
              :label="$t('common.dateTime')"
              label-for="input-sm"
          >
            <date-time-range-component
                v-model="time"
            ></date-time-range-component>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="4" sm="6">
          <select-filter-component :label-select="$t('report.creditTopUp.list.type')"
                                   :options="typeList"
                                   v-model="form.type"/>
        </b-col>
        <b-col lg="3" md="4" sm="6">
          <select-filter-component :label-select="$t('report.creditTopUp.list.by')"
                                   :options="creditList"
                                   v-model="form.user_type"/>
        </b-col>
        <b-col style="margin-top: 35px">
          <button
              type="button"
              class="btn waves-effect waves-light btn-gradient"
              @click="
              findCreditTopUp();
              findCreditTopUpSummary();
            "
          >
            {{ $t("common.Search") }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr/>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{$t('common.perPage')}}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findCreditTopUp"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="$t('empty')"
          show-empty
      >
        <template #head()="{ label, field: { key, sortable }}">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }}
        </template>
        <template #cell(current)="data">
          {{ data.item.current === '-' ? data.item.current : changeNumberFormat(data.item.current) }}
        </template>
        <template #cell(type)="data">
          <b-badge pill :class="`px-2 text-white badge ${data.item.type === 'add' ? 'bg-success' :'bg-danger' }`">
            {{filterValue(typeList , data.item.type)}}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <span :class="`text-bold ${data.item.type === 'add' ? 'text-success' :'text-danger' }`">{{ changeNumberFormat(data.item.amount) }}</span>
        </template>
        <template #cell(total_balance)="data">
          {{ changeNumberFormat(data.item.total_balance) }}
        </template>
        <template #cell(credit_by)="data">
          {{filterValue(creditList , data.item.credit_by)}}
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findCreditTopUp"
          />
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent";
import reportApi from "@/repository/reportApi";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import moment from "moment";
import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "index",
  components: {
    TotalListComponent,
    EmptyTable,
    DateTimeRangeComponent,
    TopicComponent,
    SelectFilterComponent,
  },
  data: () => ({
    totalAll: 0,
    time: [
      new Date(moment(new Date()).format("YYYY-MM-DD") + "T00:00:00"),
      new Date(moment(new Date()).format("YYYY-MM-DD") + "T23:59:59"),
    ],
    items: [],
    summary: [],
    typeList: masterData.creditTopUpList,
    creditList: masterData.creditByList,
    packageList: [],
    isBusy: false,
    form: {
      user_type: null,
      date_from: null,
      date_to: null,
      credit_by: null,
      type: null,
      $skip: 1,
      $limit: 10,
    },
    fields: [
      {
        key: "createdAt",
        label: "report.creditTopUp.list.date",
      },
      {
        key: "current",
        label: "report.creditTopUp.list.current",
      },
      {
        key: "type",
        label: "report.creditTopUp.list.type",
      },
      {
        key: "amount",
        label: "report.creditTopUp.list.amount",
      },
      {
        key: "total_balance",
        label: "report.creditTopUp.list.balance",
      },
      {
        key: "package_name",
        label: "report.creditTopUp.list.package",
      },
      {
        key: "credit_by",
        label: "report.creditTopUp.list.by",
      },
    ],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  mounted() {
    this.findCreditTopUp();
    this.findCreditTopUpSummary();
  },
  computed: {},
  methods: {
    changeNumberFormat(data) {
      return data ? functionsCommon.changeNumberFormat(data) : "0";
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    resetSearch() {
      const data = {
        user_type: null,
        date_from: null,
        date_to: null,
        credit_by: null,
        type: null,
        $skip: 1,
        $limit: 10,
      }
      this.form = data
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.findCreditTopUp();
      this.findCreditTopUpSummary();
    },
    findCreditTopUp() {
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi
          .getCreditTopUpAll(_.cloneDeep(this.form))
          .then((response) => {
            this.totalRows = response.total;
            this.totalAll = response.total;
            this.items = response.data;
          }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    findCreditTopUpSummary() {
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi
          .getCreditTopUpSumary(_.cloneDeep(this.form))
          .then((response) => {
            this.summary = response;
          })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  },
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}

.box-topic-credit {
  background-color: var(--primary-color);
  border-radius: 15px;
  margin: 10px;
  border: 1px solid var(--primary-color);
}
</style>
